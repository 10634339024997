import React, { Fragment, Component } from 'react';

export interface TestProps {
  token?: string;
  onLogoutClick?: () => void;
  apiUrl?: string;
}

export interface TestState {
  showSearchInput: boolean;
  searchQuery: string;
  showSubMenu: number;
  showNestedSubMenu: number;
  navMenu: [];
}

export interface MenuType {
  label: string;
  url: string;
  subMenu: [];
}

const NO_SUB_MENU: number = -1; 

class AppContainer extends Component<TestProps, TestState>  {
  constructor(props: any) {
    super(props);
    this.state = {
      showSearchInput: false,
      searchQuery: '',
      showSubMenu: NO_SUB_MENU,
      showNestedSubMenu: -NO_SUB_MENU,
      navMenu: []
    };
  }

  componentDidMount() {
    try {
      const getMenuOptions = async () => {
        const { apiUrl } = this.props;
        const domain = window.location.origin;
        const menuOptionsApiUrl = apiUrl && apiUrl !== "/" ? apiUrl : `${domain}/api/menu-options`;
        const response = await fetch(menuOptionsApiUrl);
        const data = await response.json();
        this.setState({ navMenu: data });
      }
      getMenuOptions();
    } catch (error) {
      console.error(error);
    }
  }

  onSubmit = (e: React.SyntheticEvent) => {
    const { searchQuery, showSearchInput } = this.state;
    e.preventDefault();
    if (searchQuery && showSearchInput) {
      window.open(`https://prcousa.com/?s=${searchQuery}`, '_self');
    } else {
      this.setState({ showSearchInput: !showSearchInput });
    }
  };

  onMenuHover = (i: number) => this.setState({ showSubMenu: i });
  onMenuLeave = () => this.setState({ showSubMenu: NO_SUB_MENU });
  onSubMenuHover = (i: number) => this.setState({ showNestedSubMenu: i });
  onSubMenuLeave = () => this.setState({ showNestedSubMenu: NO_SUB_MENU });

  redirectToHome = (e: React.SyntheticEvent) => {
    e.preventDefault()
    window.history.pushState({}, "", "/")
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }

  render() {
    const { children, token, onLogoutClick } = this.props;
    const { navMenu, showSubMenu, showNestedSubMenu, showSearchInput, searchQuery } = this.state;

    return (
      <Fragment>
        <div className="header-block">
          <div className="header-block__row-container container">
            <div className="header-block__row header-block__row--main">
              <div className="header-block__column header-block__logo header-block--auto-grow">
                <style
                  data-appended-custom-css="true"
                  dangerouslySetInnerHTML={{ __html: '.logo-image {width:159px;height:48px;}' }}
                />{' '}
                <a href="/" className="header-logo logo-image" onClick={this.redirectToHome}>
                  <img src="https://prcousa.com/wp-content/uploads/PRcoPowerLogorb.png" className="main-logo" width={159} height={159} alt="" />
                </a>
              </div>
              <div className="header-block__column header-block--content-right header-block--align-right">
                <div className="header-block__items-row">
                  <div className="header-block__item header-block__item--type-menu-main-menu header-block__item--standard-menu-container">
                    <div className="standard-menu-container menu-skin-main">
                      <nav className="nav-container-main-menu">
                        <ul id="menu-home-1" className="menu">
                          {navMenu?.length
                            ? navMenu.map((menu: MenuType, i: number) => {
                                return (
                                  <li
                                    key={i}
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item"
                                    onMouseOver={() => this.onMenuHover(i)}
                                    onMouseOut={() => this.onMenuLeave()}
                                  >
                                    <a href={menu.url}>
                                      <span className="hover-underline-animation">{menu.label}</span>
                                    </a>
                                   {menu.subMenu.length ? (<ul
                                      className="sub-menu"
                                      style={i === showSubMenu ? { opacity: 1, visibility: 'visible' } : {}}
                                    >
                                      {menu.subMenu.map((subMenu: MenuType, index: number) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`menu-item menu-item-type-custom menu-item-object-custom menu-item ${
                                              subMenu.subMenu.length ? 'menu-item-has-children' : ''
                                            }`}
                                            onMouseOver={() => this.onSubMenuHover(index)}
                                            onMouseOut={() => this.onSubMenuLeave()}
                                          >
                                            <a href={subMenu.url}>
                                              <span className="hover-underline-animation">{subMenu.label}</span>
                                            </a>
  
                                            {subMenu.subMenu.length ? (
                                              <ul
                                                className="sub-menu"
                                                style={index === showNestedSubMenu ? { opacity: 1, visibility: 'visible' } : {}}
                                              >
                                                {subMenu.subMenu.map((nestedSubMenu: MenuType, index2: number) => {
                                                  return (
                                                    <li
                                                      key={index2}
                                                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item"
                                                    >
                                                      <a href={nestedSubMenu.url}>
                                                        <span className="hover-underline-animation">{nestedSubMenu.label}</span>
                                                      </a>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            ) : null}
                                          </li>
                                        );
                                      })}
                                    </ul>) : null}
                                  </li>
                                );
                              })
                            : null}
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item">
                            {!!token && (
                              <a href="#/">
                                <span data-testid="logout-btn" onClick={onLogoutClick}>Logout</span>
                              </a>
                            )}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="header-block__item header-block__item--type-search-field header-block__item--hide-on-mobile">
                    <div className="header-search-input menu-skin-main">
                      <form role="search" onSubmit={this.onSubmit}>
                        <div
                          className="search-field"
                          style={showSearchInput ? { opacity: 1, visibility: 'visible' } : {}}
                        >
                          <span></span>
                          <input
                            type="search"
                            value={searchQuery}
                            autoComplete="off"
                            name="s"
                            placeholder="Search site..."
                            onChange={(e) => this.setState({ searchQuery: e.target.value })}
                          />
                        </div>
                        <div className="search-icon" onClick={this.onSubmit}>
                          <a href="#/" data-animation="none">
                            <svg
                              height={24}
                              width={24}
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 1417.3 1417.3"
                              // style={{ enableBackground: "new 0 0 1417.3 1417.3" }}
                              xmlSpace="preserve"
                            >
                              <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                  __html: '\n\t.st0{display:none;}\n\t.st1{display:inline;}\n',
                                }}
                              />
                              <g id="search-one">
                                <path
                                  d="M976.2,925.2c90.6-98.1,147.1-229.4,147.1-374.1C1123.4,247.7,876.5,0,572.2,0S21.1,247.7,21.1,551.1
              s246.9,551.1,551.1,551.1c123.9,0,237.7-40.7,330-109.7l409.8,409.8c10,10,22.4,15,34.9,15s25.8-5,34.9-15
              c19.1-19.1,19.1-50.7,0-70.7L976.2,925.2z M120.8,551.1c0-248.6,202-451.4,451.4-451.4c248.6,0,451.4,202,451.4,451.4
              s-202,451.4-451.4,451.4S120.8,799.7,120.8,551.1z"
                                />
                              </g>
                              <g id="search-two" className="st0">
                                <path
                                  className="st1"
                                  d="M976.2,925.2c90.6-98.1,147.1-229.4,147.1-374.1C1123.4,247.7,876.5,0,572.2,0S21.1,247.7,21.1,551.1
              s246.9,551.1,551.1,551.1c123.9,0,237.7-40.7,330-109.7l409.8,409.8c10,10,22.4,15,34.9,15s25.8-5,34.9-15
              c19.1-19.1,19.1-50.7,0-70.7L976.2,925.2z"
                                />
                              </g>
                            </svg>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="app-container-wrapper">
            {children}
          </div>
        <footer
        id="footer"
        role="contentinfo"
        className="site-footer main-footer footer-bottom-horizontal fixed-footer site-footer-inverted main-footer-inverted"
      >
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-content">
              <div className="footer-content-left">
                <div className="copyrights site-info">
                  <p>© PRco inc. {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </Fragment>
    )
   }

}

export {
  AppContainer
}